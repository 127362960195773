import io from 'socket.io-client';

let socket;

if ('development' === process.env.NODE_ENV) {
    socket = io('http://192.168.1.30:3000');
} else {
    socket = io('https://domek.kjaniszewski.pl/', {
        path: '/node/socket.io'
    });
}

$(function () {
    let $main = $('body .main'),
        clearCurrentCardTime = 1E3;

    // strona główna
    if (window.location.pathname === '/') {
        let $currentCard = null;

        const rangeInputs = document.querySelectorAll('.card input[type="range"]');

        rangeInputs.forEach(input => {
            $(input).rangeslider({
                polyfill: false
            });
        });

        $main.on('click', '.card:not([data-state="unavailable"])', (e) => {
            let $card = $(e.target);

            if (!$card.hasClass('card')) {
                if ($card.is('input[type="range"]')) {
                    return;
                }

                $card = $card.parents('.card');
            }

            let $checkbox = $card.find('[type=checkbox]');

            $checkbox.prop('checked', !$checkbox.prop('checked')).trigger('change');
        });
        $main.on('change', '.card input[type="checkbox"]', (e) => {
            let $checkbox = $(e.target),
                tuyaId = $checkbox.data('id'),
                $card = $checkbox.parents('.card'),
                state = $checkbox.prop('checked');

            $currentCard = $card;

            toggleCardState($card, state);
            socket.emit('switch', {'id': $card.data('id'), state});

            let onOff = $checkbox.prop('checked') ? 'on' : 'off';

            apiPost('/api/switch/' + onOff, {tuyaId})
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    return response.json();
                })
                .then(() => {
                    setTimeout(function () {
                        $currentCard = null;
                    }, clearCurrentCardTime);
                })
                .catch(() => toggleCardState($card));
        });
        $main.on('input', '.card input[type="range"]', (e) => {
            e.preventDefault();
            e.stopPropagation();
            let $checkbox = $(e.target),
                $card = $checkbox.parents('.card'),
                brightness = $checkbox.val();

            $currentCard = $card;
            setBrightness($card, brightness);
            socket.emit('brightness', {'id': $card.data('id'), brightness});
        });
        $main.on('change', '.card input[type="range"]', (e) => {
            e.preventDefault();
            e.stopPropagation();
            let $checkbox = $(e.target),
                $card = $checkbox.parents('.card'),
                state = $card.find('input[type="checkbox"]').prop('checked');

            $currentCard = null;
            sendBrightness($card, state);
        });
        $main.on('click', '.card input[type="range"] ~ .rangeslider', (e) => {
            e.preventDefault();
            e.stopPropagation();
            let $checkbox = $(e.target),
                $card = $checkbox.parents('.card'),
                state = $card.find('input[type="checkbox"]').prop('checked');

            $currentCard = null;
            sendBrightness($card, state);
        });
        $main.on('click', '.card.room:not([data-state="unavailable"])', (e) => {
            let $card = $(e.target);

            if (!$card.hasClass('card')) {
                if ($card.hasClass('open')) {
                    return;
                }

                $card = $card.parents('.card');
            }

            let divOffset = $card.offset(),
                divWidth = $card.width(),
                clickX = e.pageX - divOffset.left;

            if (clickX >= divWidth - 100) {
                return;
            }

            toggleCardState($card);
            socket.emit('switch', {'id': $card.data('id'), 'state': $card.data('state') === 'on'});
            let newState = $card.data('state') === 'on';

            $main.find('[data-room="' + $card.data('id') + '"]:not([data-state="unavailable"])').each((i, e) => {
                $(e).find('input[type="checkbox"]').prop('checked', newState).trigger('change');
            });
        });
        $main.on('click', '.card.room .open', (e) => {
            let $card = $(e.target).parents('.card'),
                open = !$card.data('open');

            $card.attr('data-open', open).data('open', open);

            let $roomDiv = $card.next();
            if (!$roomDiv.hasClass('row')) {
                return;
            }

            if (open) {
                $roomDiv.fadeIn();
            } else {
                $roomDiv.fadeOut(() => {
                    $roomDiv.hide();
                });
            }
        });

        socket.on('serverSwitch', (data) => {
            $currentCard = $main.find('.card[data-id="' + data.id + '"]');
            toggleCardState($currentCard, data.state);

            setTimeout(function () {
                $currentCard = null;
            }, clearCurrentCardTime);
        });
        socket.on('serverBrightness', (data) => {
            $currentCard = $main.find('.card[data-id="' + data.id + '"]');
            setBrightness($currentCard, data.brightness);

            setTimeout(function () {
                $currentCard = null;
            }, clearCurrentCardTime);
        });

        // let showModal = setTimeout(() => {
        // });
        // $main.on('mousedown touchstart', '.card', (e) => {
        //     let $card = $(e.target);
        //
        //     if (!$card.hasClass('card')) {
        //         if ($card.is('input[type="range"]')) {
        //             return;
        //         }
        //
        //         $card = $card.parents('.card');
        //     }
        //
        //     showModal = setTimeout(function () {
        //         let type = $card.data('type');
        //         if ('light' === type) {
        //             fetch('/api/light/' + $card.data('id'), {
        //                 method: 'GET'
        //             })
        //                 .then(response => response.text())
        //                 .then(function (html) {
        //                     let $modal = $main.find('#deviceModal');
        //                     $modal.find('.modal-content').html(html);
        //                     $modal.modal('show');
        //                     console.log('modal');
        //                 });
        //         }
        //     }, 3E2);
        // });
        // $main.on('mouseup touchend', '.card', () => {
        //     clearTimeout(showModal);
        // });

        setInterval(refreshDevices, 5000);

        function toggleCardState($card, state = null) {
            if (null === state) {
                state = $card.data('state') === 'off';
            }

            if (state) {
                $card.attr('data-state', 'on').data('state', 'on');
            } else {
                $card.attr('data-state', 'off').data('state', 'off');
            }
        }

        function refreshDevices() {
            apiGet('/api/rooms')
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    return response.json();
                })
                .then(rooms => {
                    $.each(rooms, (i, room) => {
                        let $card = $main.find('.room[data-id="' + room.id + '"]');
                        if (null === $currentCard || $card.data('id') !== $currentCard.data('id')) {
                            $card.attr('data-state', room.state).data('state', room.state);
                        }

                        $.each(room.devices, (i, e) => {
                            let $card = $main.find('.device[data-id="' + e.tuyaId + '"]');

                            if (null !== $currentCard && $card.data('id') === $currentCard.data('id')) {
                                return;
                            }

                            if ($card.attr('data-state') !== e.state) {
                                $card.attr('data-state', e.state).data('state', e.state);
                                $card.find('[type="checkbox"]').prop('checked', e.state === 'on');
                            }

                            if (e.brightness !== null) {
                                setBrightness($card, e.brightness);
                            }
                        });
                        $.each(room.thermometers, (i, e) => {
                            let $card = $main.find('.thermometer[data-id="' + e.id + '"]');
                            $card.find('.icon').html(e.icon);
                            $card.find('.temp .value').html(e.temp);
                            $card.find('.hum .value').html(e.hum);
                            $card.find('.time').html(e.time);
                        });
                    })
                });
        }

        function setBrightness($card, brightness) {
            let classes = $card.attr('class').split(' ');

            $card.find('.state .on .brightness').html(' ' + brightness + '%');

            for (let i = 0; i < classes.length; i++) {
                if (classes[i].startsWith('brightness-')) {
                    $card.removeClass(classes[i]);
                }
            }

            $card.addClass('brightness-' + brightness);
            $card.find('input[type="range"]').val(brightness);
        }

        function getBrightness($card) {
            let brightness = parseInt($card.find('.state .on .brightness').html());

            if (brightness <= 0 || isNaN(brightness)) {
                return 100;
            }

            return brightness;
        }

        function sendBrightness($card, state) {
            let brightness = getBrightness($card),
                tuyaId = $card.data('id');

            socket.emit('brightness', {'id': tuyaId, brightness});
            apiPost('/api/set/brightness', {tuyaId, brightness, state})
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    return response.json();
                });
        }
    }

    // Historia
    if (window.location.pathname === '/history') {
        let $historyTable = $main.find('#history-table'),
            columns = [
                {data: 'thermometer.name'},
                {data: 'temp'},
                {data: 'hum'},
                {data: 'date'}
            ];

        $historyTable.DataTable({
            serverSide: true,
            processing: true,
            ajax: {
                url: '/api/thermometer-histories',
                headers: {
                    'Accept': 'application/ld+json'
                },
                data: function (data) {
                    data.page = data.start / data.length + 1;
                    data.itemsPerPage = data.length;

                    try {
                        data.order = data.order.reduce((result, item) => {
                            if ('date' === columns[item.column].data) {
                                columns[item.column].data = 'readAt';
                            }

                            result[columns[item.column].data] = item.dir;
                            return result;
                        }, {});
                    } catch (e) {
                        delete data.order;
                    }

                    data.search = data.search.value;

                    delete data.start;
                    delete data.length;
                    delete data.draw;
                    delete data.columns;
                    delete data._;
                },
                dataFilter: function (data) {
                    let json = JSON.parse(data);
                    json.recordsTotal = json['hydra:totalItems'];
                    json.recordsFiltered = json['hydra:totalItems'];
                    json.data = json['hydra:member'];

                    json.data.forEach(function (item) {
                        item.temp = item.temp + '°C';
                        item.hum = item.hum + '%';
                    });

                    return JSON.stringify(json);
                }
            },
            columns,
            order: [[3, 'desc']],
            language: {
                'url': '//cdn.datatables.net/plug-ins/1.11.4/i18n/pl.json'
            }
        });
    }

    // Ustawienia
    if (window.location.pathname === '/settings') {
        $main.on('change', '.settings .setting [type="checkbox"]', (e) => {
            let $checkbox = $(e.target),
                name = $checkbox.data('name'),
                value = $checkbox.prop('checked') ? '1' : null;

            apiPatch('/api/settings/' + name, {value})
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    return response.json();
                });
        });
    }
});

function apiPost(url, data) {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

function apiPatch(url, data) {
    return fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/merge-patch+json'
        },
        body: JSON.stringify(data)
    });
}

function apiGet(url) {
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
