import './styles/app.scss';

require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');

import './js/main'
import './bootstrap';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'rangeslider.js/dist/rangeslider.min';

// the bootstrap module doesn't export/return anything
import 'bootstrap';

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

import lightbox from 'lightbox2';

lightbox.option({
    'imageFadeDuration': 200,
    'resizeDuration': 50,
    'albumLabel': 'Zdjęcie %1 z %2'
});

$(document).ready(function() {
    $('[data-toggle="popover"]').popover();
});

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js', { scope: '/' })
        .then((registration) => {
            console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
            console.error('Service Worker registration failed:', error);
        });
}